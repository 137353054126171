import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Create Projects" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "pal-create"
      }}>{`pal create`}</h2>
      <p><strong parentName="p">{`Alias: `}</strong>{` `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal c`}</code></p>
      <p>{`Create new NodeJs Prisma GraphQL TypeScript project`}</p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token operator"
              }}>{`>`}</span>{` pal c`}</span>{`

.______      ___       __             __       _______.
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`   _  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`    /   `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`     `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`           `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`     /       `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  /  ^  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`           `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`   `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`----`}<span parentName="code" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`\``}</span>{`
`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`   ___/  /  /_`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`   `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     .--.  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`   `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`
`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`     /  _____  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`\\`}</span>{`  `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`|`}</span>{`  `}<span parentName="span" {...{
                "className": "token variable"
              }}>{`\``}</span></span>{`----.`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`  \`--'  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` .----`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`   `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` _`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`    /__/     `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`__`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_______`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`\\`}</span>{`______/  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{`_______/

`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ Please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your start example · full-stack-nextjs`}</span>{`  ❯ full-stack-nextjs
  full-stack-gatsbyjs
  apollo-nexus-schema
  apollo-sdl-first
  graphql-modules
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ Please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your start framework · Tailwind CSS + PrismaAdmin UI`}</span>{`  Material UI
  Material UI + PrismaAdmin UI
  Tailwind CSS
  ❯ Tailwind CSS + PrismaAdmin UI
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ Use multi schema template …`}</span>{`  ❯ no
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ please enter your project name · great-project`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ please enter your project description · new NodeJs Prisma GraphQL TypeScript project`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ please enter your project author · Ahmed Elywa`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ please enter your project repository · https://github.com/paljs/prisma-tools`}</span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ Do you need to use Git …`}</span>{`  ❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
  no
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ please `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`select`}</span>{` your package manager · `}<span parentName="span" {...{
                "className": "token function"
              }}>{`yarn`}</span></span>{`  ❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  ✔ Skip package installation · no`}</span>{`  ❯ `}<span parentName="code" {...{
              "className": "token function"
            }}>{`yes`}</span>{`
  no`}</code></pre></div>
      <p>{`Answer all questuins and wait the installation`}</p>
      <h2 {...{
        "id": "starter-examples"
      }}>{`Starter examples`}</h2>
      <p>{`Start your NodeJs, GraphQL, Prisma from one of our examples.`}</p>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul">{`Full-Stack`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#full-stack-nextjs"
              }}>{`With NextJS`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#full-stack-gatsbyjs"
              }}>{`With GatsbyJS`}</a></li>
          </ul>
        </li>
        <li parentName="ul">{`Backend`}
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#apollo-nexus-schema"
              }}>{`Apollo And Nexus Schema`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#apollo-sdl-first"
              }}>{`Apollo SDL-first`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#graphql-modules"
              }}>{`Apollo And graphql-modules`}</a></li>
          </ul>
        </li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "full-stack-nextjs"
      }}>{`full-stack-nextjs`}</h2>
      <p>{`Our main example to build full-stack project with`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Prisma`}</code>{` Modern Database Access for TypeScript & Node.js`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQL`}</code>{` All of the data you need, in one request`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Nexus Schema`}</code>{` code-first GraphQL framework`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Apollo Server`}</code>{` GraphQL server to fetch and combine data from multiple sources.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Next.js`}</code>{` React framework`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`PrismaTable`}</code>{` To building dynamics tables to manage your DB tables.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Material UI`}</code>{` using Material UI framework option`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Tailwind CSS`}</code>{` using `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`Tailwind CSS`}</code>{` framework option`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/tree/master/packages/create/examples/full-stack-nextjs"
        }}>{`See project files on GitHub`}</a></p>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "full-stack-gatsbyjs"
      }}>{`full-stack-gatsbyjs`}</h2>
      <p>{`build full-stack project with`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Prisma`}</code>{` Modern Database Access for TypeScript & Node.js`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQL`}</code>{` All of the data you need, in one request`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Nexus Schema`}</code>{` code-first GraphQL framework`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Apollo Server`}</code>{` GraphQL server to fetch and combine data from multiple sources.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Gatsby.js`}</code>{` React framework`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`PrismaTable`}</code>{` To building dynamics tables to manage your DB tables.`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/tree/master/packages/create/examples/full-stack-gatsbyjs"
        }}>{`See project files on GitHub`}</a></p>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "apollo-nexus-schema"
      }}>{`apollo-nexus-schema`}</h2>
      <p>{`Start your backend Graphql API with`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Prisma`}</code>{` Modern Database Access for TypeScript & Node.js`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQL`}</code>{` All of the data you need, in one request`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Nexus Schema`}</code>{` code-first GraphQL framework`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Apollo Server`}</code>{` GraphQL server to fetch and combine data from multiple sources.`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/tree/master/packages/create/examples/apollo-nexus-schema"
        }}>{`See project files on GitHub`}</a></p>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "apollo-sdl-first"
      }}>{`apollo-sdl-first`}</h2>
      <p>{`Start your backend Graphql API with`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Prisma`}</code>{` Modern Database Access for TypeScript & Node.js`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQL`}</code>{` All of the data you need, in one request`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Apollo Server`}</code>{` GraphQL server to fetch and combine data from multiple sources.`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/tree/master/packages/create/examples/apollo-sdl-first"
        }}>{`See project files on GitHub`}</a></p>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "graphql-modules"
      }}>{`graphql-modules`}</h2>
      <p>{`Start your backend Graphql API with`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Prisma`}</code>{` Modern Database Access for TypeScript & Node.js`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQL`}</code>{` All of the data you need, in one request`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`graphql-modules`}</code>{` GraphQL Modules is a toolset of libraries and guidelines dedicated to create reusable, maintainable, testable and extendable modules out of your GraphQL server.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`Apollo Server`}</code>{` GraphQL server to fetch and combine data from multiple sources.`}</li>
      </ul>
      <p><a parentName="p" {...{
          "href": "https://github.com/paljs/prisma-tools/tree/master/packages/create/examples/graphql-modules"
        }}>{`See project files on GitHub`}</a></p>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      